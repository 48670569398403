<script>
    import notif from '$lib/stores/notif';
    import { flip } from 'svelte/animate';
    import { fly } from 'svelte/transition';
    import SpriteIcon from '$lib/components/icons/SpriteIcon.svelte';

    const icons = {
        default: 'message',
        error:   'x',
        info:    'info',
        success: 'accept',
    };
</script>

<div class='notifs'>
    {#each $notif as notif (notif.id)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div animate:flip class={`notif notch ${notif.type}`} transition:fly={{y: -30}} on:click={() => notif.dismiss()}>
            <div class='content'>
                <SpriteIcon name={icons[notif.type]} size={'40px'} />
                <span>{notif.msg}</span>
            </div>
        </div>
    {/each}
</div>

<style>
    .notifs {
        position: fixed;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 999;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        pointer-events: none;
    }

    .notif {
        flex: 0 0 auto;
        margin-bottom: 10px;
        pointer-events: initial;
        padding: 10px 14px;
        padding: var(--gap-3) var(--gap-4);
        box-shadow: 2px 2px 4px black;
        border-width: var(--border-width);

        border-color: var(--btn-color-base-light);
        border-bottom-color: var(--btn-color-base-dark);
        background-color: var(--btn-color-base);
    }
    .content {
        display: flex;
        gap: var(--gap-3);
    }
    
    .notif:global(.error) {
        border-color: var(--btn-color-red-light);
        border-bottom-color: var(--btn-color-red-dark);
        background-color: var(--btn-color-red);
    }
    .notif:global(.info) {
        border-color: var(--btn-color-yellow-light);
        border-bottom-color: var(--btn-color-yellow-dark);
        background-color: var(--btn-color-yellow);
    }
    .notif:global(.success) {
        border-color: var(--btn-color-green-light);
        border-bottom-color: var(--btn-color-green-dark);
        background-color: var(--btn-color-green);
    }

    span {
        user-select: none;
        font-family: var(--font-alt);
        font-size: 1.7em;
        line-height: 1;
        transform: translateY(calc(1.7em / 12));
        margin: auto;
    }

    @media (max-width: 768px) {
        span {
            font-size: 1.4em;
            transform: translateY(calc(1.4em / 12));
        }
    }
</style>