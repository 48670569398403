import { browser } from '$app/environment';

export const prerender = true;
export const ssr = true;
export const trailingSlash = 'always';

/** @type {import('./$types').LayoutLoad} */
export const load = ({ data, url }) => {
    return {
        ...data,
        pathname: url.pathname,
        search: browser ? url.search : '',
    };
};