<svg id='filters' width='0' height='0' style='position: absolute; overflow: hidden; visibility: hidden;'>
    <defs>
        <filter id="outline-black-1">
            <feComponentTransfer in="SourceAlpha" result="opaque">
                <feFuncA type="linear" slope="100"/>
            </feComponentTransfer>
            <feMorphology operator="dilate" radius="1" in="opaque" result="dilated"/>
            <feFlood flood-color="black" result="borderColor"/>
            <feComposite in="borderColor" in2="dilated" operator="in" result="border"/>
            <feComposite in="border" in2="opaque" operator="xor" result="outline"/>
            <feMerge>
                <feMergeNode in="final"/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
        </filter>
        <filter id="outline-black-2">
            <feComponentTransfer in="SourceAlpha" result="opaque">
                <feFuncA type="linear" slope="100"/>
            </feComponentTransfer>
            <feMorphology operator="dilate" radius="2" in="opaque" result="dilated"/>
            <feFlood flood-color="black" result="borderColor"/>
            <feComposite in="borderColor" in2="dilated" operator="in" result="border"/>
            <feComposite in="border" in2="opaque" operator="xor" result="outline"/>
            <feMerge>
                <feMergeNode in="final"/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
        </filter>
    </defs>
</svg>