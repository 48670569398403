<script>
    import { onMount } from 'svelte';

    let visible = false;
    
    onMount(() => {
        const t = setTimeout(() => (visible = true), 200);
        return () => clearTimeout(t);
    });
</script>

{#if visible}
    <div role='progressbar' class='progress-container'>
        <div class='progress' />
    </div>
{/if}

<style>
    .progress-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        z-index: 999;
    }
    
    .progress {
        height: 100%;
        background-color: hsl(210, 96%, 48%);
        background: linear-gradient(90deg, hsl(210, 96%, 48%), hsl(210, 96%, 48%, 0.2), hsl(210, 96%, 48%));
        background-size: 200% 100%;
        animation: progress 3s cubic-bezier(0.22, 0.61, 0.36, 1) forwards, wave 1s linear infinite;
    }
    
    @keyframes wave {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: 0% 0;
        }
    }

    @keyframes progress {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
    }
</style>