<script>
    import { fade } from 'svelte/transition';
    import SpriteIcon from '$lib/components/icons/SpriteIcon.svelte';
    import { browser } from '$app/environment';

    export let parent = browser ? window : null;
    
    let y, visible;
    const top = () => parent.scrollTo({ top: 0, behavior: 'smooth'});
    $: visible = y > 300;
</script>

{#if visible}
    <button transition:fade on:click={() => top()} class='notch' title='Back to Top'>
        <SpriteIcon name='up' size='35px' />
    </button>
{/if}

<svelte:window bind:scrollY={y} />

<style>
    button {
        display: flex;
        position: fixed;
        bottom: 10px;
        left: 10px;
        width: 60px;
        height: 60px;
        opacity: 0.7;
        border-style: solid;
        border-width: var(--border-width);
        border-color: var(--btn-color-base-light);
        border-bottom-color: var(--btn-color-base-dark);
        background-color: var(--btn-color-base);
        transition: all 0.3s ease-in-out;
    }
    button:hover {
	    opacity: 1;
    }
</style>