<script>
    import '$styles/app.css';

    import { navigating } from '$app/stores';
    import * as config from '$lib/config/public';

    import LoadingBar from '$lib/components/LoadingBar.svelte';
    import BackToTop from '$lib/components/BackToTop.svelte';
    import Notifs from '$lib/components/Notifs.svelte';
    import SEO from '$lib/components/SEO/SEO.svelte';
    import Filters from '$lib/components/sprite/Filters.svelte';
</script>

<svelte:head>
    <meta name='theme-color' content={config.site.theme_color}>
</svelte:head>

<Filters />

{#if $navigating}
	<LoadingBar />
{/if}

<Notifs />
<slot />
<BackToTop />
<SEO />